/**
 * Cookie Consent - kopfstand Gmbh
 *
 * All rights reserved!
 */
(function () {
    class ksCookieConsent {
        /**
         * Main constructor
         */
        constructor() {
            this.HEADER = "header";
            this.FOOTER = "footer";
            this.OPTIN = "optIn";
            this.OPTOUT = "optOut";

            this.BANNER_POSITION_TOP = "top";
            this.BANNER_POSITION_BOTTOM = "bottom";

            this.bannerElement = null;
            this.modalElement = null;

            // Initialize the configuration

            this.config = {
                debug: false,
                cookieName: "ksconsent",
                cookieDomain: document.location.hostname,
                cookiePath: "/",
                cookieLifetime: 365,
                cookieVersion: '1.0.0',
                respectDoNotTrack: false,
                reloadAfterConsent: false,
                language: "de",
                locale: {
                    de: {
                        bannerText:
                            "Diese Website verwendet Cookies, um bestmöglichen Service zu gewährleisten.",
                        linkSettings: "Cookie Einstellungen",
                        buttonAcceptAll: "Alle Cookies akzeptieren",
                        modalTitle: "Cookie Einstellungen",
                        modalButtonSave: "Einstellungen speichern",
                        modalButtonAcceptAll: "Alle Cookies akzeptieren",
                        modalAffectedSolutions: "Enthaltene Cookies",
                        closeIconTitle: "Einstellungen schließen",
                    },
                },
                theme: {
                    // Rounded Corners
                    borderRadius: false,

                    // Cookie Banner
                    bannerPosition: this.BANNER_POSITION_BOTTOM, // top | bottom
                    bannerBg: "#dedede",
                    bannerTextColor: "#333",

                    // Cookie Modal
                    modalHeaderBg: "#dedede",
                    modalHeaderText: "#333",
                    modalBodyBg: "#FFF",
                    modalBodyTextColor: "#000",
                    modalFooterBg: "#dedede",
                    modalCloseIcon: "#333",

                    // Checkboxes
                    checkboxBg: "#EEEEEE",
                    checkboxBgChecked: "#28a745",
                    checkboxBgDisabled: "#777",

                    // Button: Primary
                    btnPrimaryBg: "#28a745",
                    btnPrimaryTextColor: "#FFF",
                    btnPrimaryTextDecoration: "none",
                    btnPrimaryTextTransform: "none",
                    btnPrimaryCursor: "pointer",

                    // Button: Secondary
                    btnSecondaryBg: "transparent",
                    btnSecondaryTextColor: "#333",
                    btnSecondaryTextDecoration: "underline",
                    btnSecondaryTextTransform: "none",
                    btnSecondaryCursor: "default",
                },
                categories: {},
                services: {},
            };
        }

        /**
         * Updates the configuration and triggers the initilization process,
         * after the page is loaded and the body exists.
         *
         * @param {*} options
         */
        load(options) {
            // Update configuration with options
            if (Object.keys(options).length) {
                this.config = this.merge(this.config, options);
            }

            // Check for doNotTrack settings and stop processing, if enabled
            if (this.config.respectDoNotTrack === true
                && this.isDoNotTrackEnabled()) {
                return;
            }

            // Get the current cookie, if it exists or set defaults
            this.consentCookie = this.getConsentCookie();

            // Run all opt-in scripts that should be placed in the header
            const headerServices = this.getOptedInServices(this.HEADER);

            headerServices.forEach(service => {
                this.addCode(
                    service.name,
                    this.OPTIN,
                    service.optIn.code,
                    this.HEADER
                );
            });

            window.onload = () => {
                // Run all opt-in scripts, that should be placed in the footer
                const footerService = this.getOptedInServices(this.FOOTER);

                footerService.forEach(service => {
                    this.addCode(
                        service.name,
                        this.OPTIN,
                        service.optIn.code,
                        this.FOOTER
                    );
                });

                // Add inline styles for banner and modal
                if (!document.querySelector("#ksCookieConsentStyles")) {
                    this.createStyles();
                }

                // Display the banner, if needed
                if (this.config.debug === true || this.consentCookie.revision === 0) {
                    this.showBanner();
                }
            };

            // Add even listener for links to open the modal
            document.addEventListener("click", (e) => {
                const target = e.target;
                const targetNodeName = e.target.nodeName.trim().toLowerCase();

                if (targetNodeName === "a") {
                    const href = target.getAttribute("href");
                    const classList = target.getAttribute("class");

                    if (href.includes("ks-cookie-dialog") || classList.includes("ks-cookie-dialog")) {
                        e.preventDefault();
                        this.showModal();
                    }
                }
            });
        }

        /**
         * Returns an array of cookies, set in the browser.
         */
        getCookies() {
            const cookieList = document.cookie.split("; ");
            const cookies = {};

            cookieList.forEach((cookie) => {
                const cookieParts = cookie.split("=");
                cookies[cookieParts[0]] = cookieParts[1];
            });

            return cookies;
        }

        /**
         * Reads the consent cookie from the browser, if it exists.
         */
        getConsentCookie() {
            const cookies = this.getCookies();

            if (!cookies.hasOwnProperty(this.config.cookieName)) {
                return {
                    version: this.config.cookieVersion,
                    revision: 0,
                    categories: {},
                    services: [],
                };
            }

            return JSON.parse(
                cookies[this.config.cookieName]
            );
        }

        /**
         * Returns the active (user has consented) services with opt-in code,
         * filtered by their position.
         *
         * @param {string} position (header | footer)
         */
        getOptedInServices(position) {
            if (this.consentCookie.services.length === 0) {
                return [];
            }

            const activeServices = this.consentCookie.services.filter(serviceKey => {
                return this.config.services.hasOwnProperty(serviceKey)
                    && this.config.services[serviceKey].hasOwnProperty('optIn')
                    && (
                        !this.config.services[serviceKey].optIn.position
                        || this.config.services[serviceKey].optIn.position === position
                    );
            });

            return activeServices.map(serviceKey => {
                return {
                    name: serviceKey,
                    ...this.config.services[serviceKey]
                };
            });
        }

        /**
         * Parses a code and splits it into script tags and all other HTML.
         *
         * This is needed to create the script tags using document.createElement, as they would not get
         * executed otherwise. The identifier is used to generate a classname for all script elements,
         * that are generated, to be able to remove already existing ones, before adding them again.
         *
         * @param {string} identifier    An identifier (usually a service name)
         * @param {string} optMode       The opt mode (optIn | optOut)
         * @param {string} code          The code to inject
         * @param {string} position      The position, where the code gets injected (header | footer)
         */
        addCode(identifier, optMode, code, position = this.HEADER) {
            if (code && typeof code === "string" && code.length !== 0) {
                // Generate a classname, to find already added script elements
                const className = `ksCookieConsent_${identifier}_${position}_${optMode}`;

                // Choose the target element for injecting the code
                let targetElement = document.head;

                if (position === this.FOOTER) {
                    targetElement = document.body;
                }

                // Remove existing script elements
                let existingScriptElements = targetElement.querySelectorAll(`.${className}`);

                if (existingScriptElements.length !== 0) {
                    existingScriptElements.forEach(scriptElement => {
                        scriptElement.parentElement.removeChild(scriptElement);
                    });
                }

                // On opt-in also remove old opt-out elements
                if (optMode === this.OPTIN) {
                    const className = `ksCookieConsent_${identifier}_${position}_${this.OPTOUT}`;
                    let existingScriptElements = targetElement.querySelectorAll(`.${className}`);

                    if (existingScriptElements.length !== 0) {
                        existingScriptElements.forEach(scriptElement => {
                            scriptElement.parentElement.removeChild(scriptElement);
                        });
                    }
                }

                // Parse the code
                const decoded = atob(code);
                const parsedElements = this.parseCode(decoded);

                // Create new script elements
                let scriptElements = [];

                if (parsedElements.scripts.length !== 0) {
                    parsedElements.scripts.forEach(script => {
                        const scriptElement = document.createElement('script');

                        if (Object.keys(script.attributes).length !== 0) {
                            for (let attributeName in script.attributes) {
                                const attributeValue = script.attributes[attributeName];
                                scriptElement.setAttribute(attributeName, attributeValue);
                            }
                        }

                        if (scriptElement.className.length !== 0) {
                            scriptElement.className += " ";
                        }

                        scriptElement.className += className;

                        if (script.content.length !== 0) {
                            scriptElement.textContent = script.content;
                        }

                        scriptElements.push(scriptElement);
                    })
                }

                targetElement.insertAdjacentHTML("beforeend", parsedElements.html);

                scriptElements.forEach(scriptElement => {
                    targetElement.appendChild(scriptElement);
                });
            }
        }

        /**
         * Returns the translation for a string, using a dot syntax.
         *
         * Main translations in config.locale:
         * - main.bannerText
         *
         * Category translations in config.categories:
         * - categories.essential.name
         * - categories.marketing.name
         *
         * Service translations in config.services:
         * - services.googletagmanager.name
         * - services.analytics.name
         *
         * @param {string} key
         */
        __(key) {
            const keyParts = key.split(".");
            let locale = null;

            switch (keyParts[0]) {
                // Main translations
                case "main":
                    locale = this.config.locale[this.config.language];

                    if (!locale || !locale.hasOwnProperty(keyParts[1])) {
                        return "";
                    }

                    return locale[keyParts[1]];

                // Translations for categories
                case "categories":
                    if (
                        keyParts.length < 2 ||
                        !this.config.categories.hasOwnProperty(keyParts[1])
                    ) {
                        return "";
                    }

                    locale = this.config.categories[keyParts[1]].locale[
                        this.config.language
                        ];

                    if (!locale || !locale.hasOwnProperty(keyParts[2])) {
                        return "";
                    }

                    return locale[keyParts[2]];

                // Translations for services
                case "services":
                    if (
                        keyParts.length < 2 ||
                        !this.config.services.hasOwnProperty(keyParts[1])
                    ) {
                        return "";
                    }

                    locale = this.config.services[keyParts[1]].locale[
                        this.config.language
                        ];

                    if (!locale || !locale.hasOwnProperty(keyParts[2])) {
                        return "";
                    }

                    return locale[keyParts[2]];
            }

            return "";
        }

        /**
         * Displays the cookie banner.
         */
        showBanner() {
            if (!this.bannerElement) {
                this.createBanner();
            } else {
                this.bannerElement.style.display = "flex";
            }
        }

        /**
         * Hides the cookie banner.
         */
        hideBanner() {
            if (this.bannerElement) {
                this.bannerElement.style.display = "none";
            }
        }

        /**
         * Creates the banner element and appends it to the body.
         */
        createBanner() {
            const bannerHTML = `
                <div id="ksCookieConsentBar">
                  <div id="ksCookieConsentBar__text">
                      <p>${this.__("main.bannerText")}</p>
                  </div>
                  <div>
                      <button id="ksCookieConsentBar__button_settings"
                              class="ksCookieConsent__btn--secondary">
                        ${this.__("main.linkSettings")}
                      </button>
                      <button id="ksCookieConsentBar__button_confirm_all"
                              class="ksCookieConsent__btn--primary">
                        ${this.__("main.buttonAcceptAll")}
                      </button>
                  </div>
                </div>
              `;

            const bannerElement = document.createElement("div");
            bannerElement.id = "ksCookieConsentBanner";
            bannerElement.innerHTML = bannerHTML;

            document.body.appendChild(bannerElement);
            this.bannerElement = bannerElement;

            document
                .querySelector("#ksCookieConsentBar__button_settings")
                .addEventListener("click", (e) => {
                    e.preventDefault();
                    this.showModal();
                });

            document
                .querySelector("#ksCookieConsentBar__button_confirm_all")
                .addEventListener("click", () => {
                    this.acceptAll();
                });
        }

        /**
         * Displays the modal dialog for cookie settings.
         */
        showModal() {
            if (!this.modalElement) {
                this.createModal();
            }

            this.modalElement.style.display = "block";
        }

        /**
         * Hides the modal dialog for cookie settings.
         */
        hideModal() {
            if (this.modalElement) {
                this.modalElement.style.display = 'none';
            }
        }

        /**
         * Creates the modal dialog for cookie settings in the DOM, including all configured
         * categories and services.
         */
        createModal() {
            const modalHTML = `
                <div id="ksCookieConsentModal__content">
                    <div id="ksCookieConsentModal__header">
                      ${this.__("main.modalTitle")}
                        <span id="ksCookieConsentModal__close" title="${this.__("main.closeIconTitle")}">&times;</span>
                    </div>
                    <div id="ksCookieConsentModal__body">
                        ${Object.keys(this.config.categories).map((categoryKey) => {
                            const category = this.config.categories[categoryKey];
                            const categoryServices = this.getCategoryServices(
                                categoryKey
                            );
    
                            return `
                                <div class="ksCookieConsentModal__category">
                                    <label class="ksCookieConsentModal__category__label ${category.needed ? "disabled" : ""}">
                                      ${this.__(`categories.${categoryKey}.name`)}
                                      <input type="checkbox"
                                             class="ksCookieConsentModal__category__checkbox"
                                             ${category.checked || this.categoryIsConsented(categoryKey) ? `checked="checked"` : ""}
                                             ${category.needed ? `disabled="disabled"` : ""}
                                             value="${categoryKey}">
                                      <span></span>
                                    </label>
                                    <div class="ksCookieConsentModal__category__description">
                                        <p>${this.__(`categories.${categoryKey}.description`)}</p>
                                    </div>
                                    ${Object.keys(categoryServices).length !== 0 ? `
                                        <div class="ksCookieConsentModal__category__services">
                                          ${this.__("main.modalAffectedSolutions")}:
                                        
                                          <ul class="ksCookieConsentModal__category__services__list">
                                            ${Object.keys(categoryServices).map((serviceKey) => {
                                                return `
                                                    <li class="ksCookieConsentModal__category__services__list__item">
                                                        ${this.__(`services.${serviceKey}.name`)}
                                                    </li>
                                                `;
                                            })
                                            .join("")}
                                          </ul>
                                        </div>
                                    ` : "" }
                                </div>
                            `;
                        })
                        .join("")}
                    </div>
                    <div id="ksCookieConsentModal__footer">
                        <button id="ksCookieConsentModal__button_save_selected"
                                class="ksCookieConsent__btn--secondary">
                            ${this.__("main.modalButtonSave")}
                        </button>
                        <button id="ksCookieConsentModal__button_accept_all"
                                class="ksCookieConsent__btn--primary">
                            ${this.__("main.modalButtonAcceptAll")}
                        </button>
                    </div>
                </div>
            `;

            const modalElement = document.createElement("div");
            modalElement.id = "ksCookieConsentModal";
            modalElement.innerHTML = modalHTML;

            document.body.appendChild(modalElement);
            this.modalElement = modalElement;

            document
                .querySelector("#ksCookieConsentModal__button_save_selected")
                .addEventListener("click", () => {
                    this.saveSelection();
                });

            document
                .querySelector("#ksCookieConsentModal__button_accept_all")
                .addEventListener("click", () => {
                    this.acceptAll();
                });

            document
                .querySelector('#ksCookieConsentModal__close')
                .addEventListener("click", () => {
                    this.hideModal();
                })

            window
                .addEventListener('click', (e) => {
                    if (e.target === this.modalElement) {
                        this.hideModal();
                    }
                })
        }

        /**
         * Saves the selected categories and updates the cookie.
         */
        saveSelection() {
            let categories = {};
            let wantedCategories = [];

            // Collect all checked categories
            document
                .querySelectorAll(".ksCookieConsentModal__category__checkbox")
                .forEach((checkbox) => {
                    categories[checkbox.value] = {
                        name: checkbox.value,
                        wanted: checkbox.checked,
                    };

                    if (checkbox.checked) {
                        wantedCategories.push(checkbox.value);
                    }
                });

            // Collect the services of all checked categories
            let selectedServices = [];

            wantedCategories.forEach((category) => {
                const services = this.getCategoryServices(category);

                Object.keys(services).forEach((serviceKey) => {
                    selectedServices.push(serviceKey);
                });
            });

            // Update cookie data
            this.consentCookie.revision++;
            this.consentCookie.categories = categories;
            this.consentCookie.services = selectedServices;

            this.saveConsentCookie();

            // Hide banner and modal dialog
            this.hideBanner();
            this.hideModal();

            // Reload the document
            if (this.config.reloadAfterConsent === true) {
                document.location.reload();
            }
        }

        /**
         * Accepts all cookie categories and updates checkboxes in the modal, if it
         * is loaded in the DOM.
         */
        acceptAll() {
            // Select all categories
            let categories = {};

            for (let categoryKey in this.config.categories) {
                categories[categoryKey] = {
                    name: categoryKey,
                    wanted: true,
                }
            }

            // Select all services
            let services = Object.keys(this.config.services);

            // Update the consent cookie
            this.consentCookie.revision++;
            this.consentCookie.categories = categories;
            this.consentCookie.services = services;

            this.saveConsentCookie();

            // Update modal checkboxes, if they are loaded in the DOM
            const checkboxes = document.querySelectorAll(".ksCookieConsentModal__category__checkbox");

            if (checkboxes.length !== 0) {
                checkboxes.forEach(checkbox => {
                    checkbox.checked = true;
                });
            }

            // Hide the banner and modal dialog
            this.hideBanner();
            this.hideModal();

            // Reload the page
            if (this.config.reloadAfterConsent === true) {
                document.location.reload();
            }
        }

        /**
         * Creates the consent cookie or updates it, if it already exists.
         * For all new services, the opt-in script will run and for all removed ones the opt-out script.
         */
        saveConsentCookie() {
            // Get the current cookie version from the browser
            const consentCookieBefore = this.getConsentCookie();

            // Update the cookie
            const cookieContent = JSON.stringify(this.consentCookie);
            const cookieExpirationMs = new Date().getTime() + this.config.cookieLifetime * 60 * 60 * 24;
            const cookieExpirationDate = new Date(cookieExpirationMs);

            let consentCookie = `${this.config.cookieName}=${cookieContent}; ` +
                `expires=${cookieExpirationDate}; ` +
                `path=${this.config.cookiePath}; ` +
                `domain=${this.config.cookieDomain}; `+
                `sameSite=Strict;`;

            if (window.location.protocol === 'https:') {
                consentCookie += " Secure";
            }

            document.cookie = consentCookie;

            // Collect services that get opted in and opted out
            let optInServices = [];
            let optOutServices = [];

            if (consentCookieBefore.revision === 0) {
                optInServices = this.consentCookie.services;
            } else {
                optInServices = this.consentCookie.services.filter(service => {
                    return !consentCookieBefore.services.includes(service);
                });

                optOutServices = consentCookieBefore.services.filter(service => {
                    return !this.consentCookie.services.includes(service);
                });
            }

            // Run opt-in codes for changed services
            optInServices.forEach(service => {
                if (this.config.services.hasOwnProperty(service) && this.config.services[service].hasOwnProperty("optIn")) {
                    const optIn = this.config.services[service].optIn;

                    if (optIn.hasOwnProperty("position")) {
                        this.addCode(
                            service,
                            this.OPTIN,
                            optIn.code,
                            optIn.position
                        );
                    } else {
                        this.addCode(
                            service,
                            this.OPTIN,
                            optIn.code
                        );
                    }
                }
            })

            // Run opt-out codes for changed services
            optOutServices.forEach(service => {
                if (this.config.services.hasOwnProperty(service) && this.config.services[service].hasOwnProperty("optOut")) {
                    const optOut = this.config.services[service].optOut;

                    if (optOut.hasOwnProperty("position")) {
                        this.addCode(
                            service,
                            this.OPTOUT,
                            optOut.code,
                            optOut.position
                        );
                    } else {
                        this.addCode(
                            service,
                            this.OPTOUT,
                            optOut.code
                        );
                    }
                }
            })
        }

        /**
         * Returns if a category was consented by the user.
         *
         * @param {string} categoryKey
         */
        categoryIsConsented(categoryKey) {
            return this.consentCookie.revision !== 0
                && this.consentCookie.categories.hasOwnProperty(categoryKey)
                && this.consentCookie.categories[categoryKey].wanted === true;
        }

        /**
         * Adds the inline styles for banner and modal.
         */
        createStyles() {
            const styleElement = document.createElement("style");
            styleElement.id = "ksCookieConsentStyles";
            styleElement.innerHTML = this.buildStyles();

            document.head.appendChild(styleElement);
        }

        /**
         * Returns the CSS needed for the banner and modal.
         */
        buildStyles() {
            return `
                @keyframes ksCookieConsentAnimateTop {
                  from {
                    top: -300px;
                    opacity: 0;
                  }
        
                  to {
                    top: 0;
                    opacity: 1;
                  }
                }
        
                /* Consent Modal */
        
                #ksCookieConsentModal {
                  display: none;
                  position: fixed;
                  margin: 0;
                  padding: 100px 0 0 0;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  overflow: auto;
                  z-index: 999999;
                  background-color: rgb(0,0,0);
                  background-color: rgba(0,0,0,0.4);
                }
                
                /* modal content */
        
                #ksCookieConsentModal__content {
                  position: relative;
                  background-color: #fff;
                  margin: auto;
                  padding: 0;
                  width: 100%;
                  max-width: 600px;
                  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
                  animation-name: ksCookieConsentAnimateTop;
                  animation-duration: 0.4s;
                  ${this.config.theme.borderRadius ? 'border-radius: 4px;' : ''}
                }
        
                /* Modal Header */
        
                #ksCookieConsentModal__header {
                  padding: 11px 15px;
                  background-color: ${this.config.theme.modalHeaderBg};
                  color: ${this.config.theme.modalHeaderText};
                  line-height: 28px;
                  font-weight: bold;
                  ${this.config.theme.borderRadius ? 'border-top-left-radius: 4px;' : ''}
                  ${this.config.theme.borderRadius ? 'border-top-right-radius: 4px;' : ''}
                }
        
                /* Modal Close Button */
        
                #ksCookieConsentModal__close {
                    color: ${this.config.theme.modalCloseIcon};
                    float: right;
                    font-size: 28px;
                    font-weight: bold;
                    cursor: pointer;
                }
                
                /* Modal Body */
        
                #ksCookieConsentModal__body {
                    padding: 30px 30px 0;
                }
            
                #ksCookieConsentModal__body p {
                    margin: 0 0 30px;
                    padding: 0;
                    font-size: inherit;
                    color: ${this.config.theme.modalBodyTextColor}
                }
        
                /* Checkbox */
                
                #ksCookieConsentModal__body label {
                    display: block;
                    position: relative;
                    padding: 0 0 0 35px;
                    margin: 0 0 6px 0;
                    background: none;
                    line-height: 28px;
                    cursor: pointer;
                    font-size: inherit;
                    font-weight: bold;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    color: ${this.config.theme.modalBodyTextColor}
                }
        
                #ksCookieConsentModal__body label.disabled { 
                  cursor: default;
                }
        
                /* Hide original browser checkbox */
        
                #ksCookieConsentModal__body label input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    margin: 0;
                    padding: 0;
                }
        
                /* Custom checkbox */
                
                #ksCookieConsentModal__body label span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: ${this.config.theme.checkboxBg};
                    ${this.config.theme.borderRadius ? 'border-radius: 3px;' : ''}
                }
        
                /* Checked Background */
        
                #ksCookieConsentModal__body label input:checked ~ span {
                    background-color: ${this.config.theme.checkboxBgChecked};
                }
            
                /* Disabled Background */
        
                #ksCookieConsentModal__body label input:disabled ~ span {
                    background-color: ${this.config.theme.checkboxBgDisabled};
                }
        
                /* Checkmark hidden */
                
                #ksCookieConsentModal__body label span:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
        
                /* Checkmark visible */
            
                #ksCookieConsentModal__body label input:checked ~ span::after {
                    display: block;
                }
        
                /* Checkmark style */
        
                #ksCookieConsentModal__body label span:after {
                    left: 9px;
                    top: 6px;
                    width: 8px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
        
                /* Service List */
        
                #ksCookieConsentModal__body .ksCookieConsentModal__category__services__list {
                  margin: 1rem 0 2rem 0;
                  padding: 0;
                  list-style: none;
                }
                
                #ksCookieConsentModal__body .ksCookieConsentModal__category__services__list__item {
                  margin: 0;
                  padding: 0 0 0 1rem;
                }
                
                #ksCookieConsentModal__body .ksCookieConsentModal__category__services__list__item::before {
                  display: inline-block;
                  content: "- ";
                  margin-right: 0.5rem;
                }
                    
                /* Modal Footer */
        
                #ksCookieConsentModal__footer {
                    padding: 0 15px 15px;
                    background-color: ${this.config.theme.modalFooterBg};
                    color: white;
                    text-align: right;
                    ${this.config.theme.borderRadius ? 'border-bottom-left-radius: 4px;' : ''}
                    ${this.config.theme.borderRadius ? 'border-bottom-right-radius: 4px;' : ''}
                }
                
                /* Consent Bar */
        
                #ksCookieConsentBar {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    padding: 0 15px 15px;
                    position: fixed;
                    left: 0;
                    ${
                        this.config.theme.bannerPosition === this.BANNER_POSITION_TOP
                            ? "top: 0;"
                            : "bottom: 0;"
                    }
                    background-color: ${this.config.theme.bannerBg};
                    color: ${this.config.theme.bannerTextColor};
                    z-index: 999990;
                }
        
                /* bar text */
        
                #ksCookieConsentBar__text p {
                    margin: 30px 0 0;
                    color: ${this.config.theme.bannerTextColor};
                }
                
                /* consent buttons */
        
                .ksCookieConsent__btn--primary,
                .ksCookieConsent__btn--secondary {
                    border: none;
                    ${this.config.theme.borderRadius ? 'border-radius: 4px;' : ''}
                    padding: 15px;
                    font-family: inherit;
                    font-size: inherit;
                }
        
                .ksCookieConsent__btn--primary {
                    margin-top: 15px;
                    background-color: ${this.config.theme.btnPrimaryBg};
                    color: ${this.config.theme.btnPrimaryTextColor};
                    text-decoration: ${this.config.theme.btnPrimaryTextDecoration};
                    text-transform: ${this.config.theme.btnPrimaryTextTransform};
                    cursor: ${this.config.theme.btnPrimaryCursor};
                    font-weight: bold;
                }
                
                .ksCookieConsent__btn--secondary {
                    margin: 15px 15px 0 0;
                    background-color: ${this.config.theme.btnSecondaryBg};
                    color: ${this.config.theme.btnSecondaryTextColor};
                    text-decoration: ${this.config.theme.btnSecondaryTextDecoration};
                    text-transform: ${this.config.theme.btnSecondaryTextTransform};
                    cursor: ${this.config.theme.btnSecondaryCursor};
                }
            `;
        }

        /**
         * Returns the services that belong to a given category key.
         *
         * @param {string} categoryKey
         */
        getCategoryServices(categoryKey) {
            if (!this.config.categories.hasOwnProperty(categoryKey)) {
                return {};
            }

            let services = {};

            for (let serviceKey in this.config.services) {
                const service = this.config.services[serviceKey];

                if (service.category === categoryKey) {
                    services[serviceKey] = service;
                }
            }

            return services;
        }

        /**
         * Parses the DOM of code, that should be used on opt-in or opt-out.
         *
         * Script tags get stripped from the HTML and are returned as seperate property,
         * that contains an array of all scripts with their attributes and contents.
         *
         * @param {string} code
         */
        parseCode(code) {
            let scripts = [];

            const placeholder = document.createElement('html');
            placeholder.innerHTML = `<html><head></head><body>${code}</body></html>`;
            const placeholderScripts = placeholder.querySelectorAll("script");

            Array.from(placeholderScripts).forEach(script => {
                let attributes = {};

                for (let name in script.attributes) {
                    if (script.attributes[name].nodeName) {
                        attributes[script.attributes[name].nodeName] = script.attributes[name].nodeValue;
                    }
                }

                scripts.push({
                    attributes,
                    content: script.textContent,
                });

                script.parentElement.removeChild(script);
            });

            const html = placeholder.getElementsByTagName("body")[0].innerHTML.trim();

            return {
                scripts,
                html
            };
        }

        /**
         * Returns whether or not DNT (Do Not Track) is enabled in the browser or not.
         *
         * @returns {boolean}
         */
        isDoNotTrackEnabled() {
            if (window.doNotTrack
                || navigator.doNotTrack
                || navigator.msDoNotTrack
                || (window.external && 'msTrackingProtectionEnabled' in window.external)
            ) {
                // Chrome, Opera, Safari, Firefox
                if ("doNotTrack" in navigator
                    && (navigator.doNotTrack === "yes" || navigator.doNotTrack === "1")) {
                    return true;
                }

                // Safari, IE 11, Edge
                if ("doNotTrack" in window && window.doNotTrack === "1") {
                    return true;
                }

                // IE 10 and older
                if ("msDoNotTrack" in navigator && navigator.msDoNotTrack === "1") {
                    return true;
                }

                // Most IE versions
                if ("external" in window
                    && 'msTrackingProtectionEnabled' in window.external
                    && window.external.msTrackingProtectionEnabled()) {
                    return true;
                }
            }

            return false;
        }

        /**
         * Merges 2 objects and overwrites existing values.
         *
         * @param {Object} current
         * @param {Object} updates
         * @returns {*}
         */
        merge(current, updates) {
            for (let key of Object.keys(updates)) {
                if (!current.hasOwnProperty(key) || typeof updates[key] !== 'object') {
                    current[key] = updates[key];
                } else {
                    this.merge(current[key], updates[key]);
                }
            }

            return current;
        }
    }

    // Set global variable
    window.ksCookieConsent = new ksCookieConsent();
})();